<!-- 安防预警 -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <span style="color: #606266;padding-left: 15px">站点名称：</span>
        <el-select v-model="value" placeholder="请选择站点名称">
          <el-option
            v-for="(item,index) in options"
            :key="item.id"
            :label="index+1+'  '+item.siteName"
            :value="item.siteName">
          </el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称"></el-table-column>
        <el-table-column prop="alarmStatus.infraRedStatus" :formatter="stateFormat" label="红外报警"></el-table-column>
        <el-table-column prop="alarmStatus.accessControlStatus" :formatter="accessControlStatus" label="门禁报警">
        </el-table-column>
        <el-table-column prop="alarmStatus.perimeterStatus" :formatter="perimeterStatus" label="周界报警"></el-table-column>
        <el-table-column prop="alarmStatus.storageDeviceStatus" :formatter="storageDeviceStatus"
                         label="存储定位"></el-table-column>
        <el-table-column prop="deploymentStatus" :formatter="Securitystatus" label="安防状态"></el-table-column>
        <el-table-column prop="deviceStatus" :formatter="Securitystatusshebei" label="设备状态"></el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="policeBtn(scope.row)">查看报警</el-button>
            <el-button class="edit" @click="handleEdit(scope.row,4)">布防</el-button>
            <el-button class="delete" @click="handleDelete(scope.row,5)">撤防</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrent"
      >
      </el-pagination>
    </div>


    <!--   开启布控   -->
    <startcontrol ref="startcontrolClick"></startcontrol>
    <!--  关闭布控  -->
    <closecontrol ref="closecontrolClick"></closecontrol>
  </div>
</template>

<script>
import startcontrol from '../startcontrol.vue' // 开启布控
import closecontrol from '../closecontrol.vue' // 关闭布控
import {
  userUpdate,
  userFindAll,
  userDepartment
} from '../../api/user'
// import { userFall } from "./validate";
var isUserFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入降雨量'))
  } else if (!userFall(value)) {
  } else if (!userFall(value)) {
    callback(new Error('请输入正确的降雨量'))
  } else {
    callback()
  }
}

export default {
  components: {
    startcontrol,
    closecontrol
  },
  data() {
    return {
      tableData: [], //用户列表
      checked: false,
      title: '',
      page: 1,
      size: 10,
      total: 0, //总条数
      value: '', // 站点名称
      loading: true,
      idList: null,// 权限列表
      currentid: 0, // 当前用户id
      spareId: 0,// 取出的id
      options: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.currentid = Number(localStorage.getItem('id'))
    this.getUserList()
    this.user()
    this.getStateicon()
  },
  mounted() {
  },
  methods: {
    // 获取站点名称
    getStateicon() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999
      }).then((res) => {
        if (res.code == 200) {
          if (res.data.list != "") {
            this.options = res.data.list
          }
        }
      })
    },
    // 查找当前权限
    user() {
      this.$axios.get(`/sysSettings/selectSysSettingsList`).then((res) => {
        this.idList = res.data
        this.idList = this.idList[0].examinePerson.split(',')
        this.idList.forEach((item, index) => {
          if (item == this.currentid) {
            this.spareId = item
          }
        })
      })
    },
    // 红外报警
    stateFormat(row, column) {
      if (row.alarmStatus.infraRedStatus == 0) {
        return '正常'
      } else {
        return '报警'
      }
    },
    // 门禁报警
    accessControlStatus(row, column) {
      if (row.alarmStatus.accessControlStatus == 0) {
        return '正常'
      } else {
        return '报警'
      }
    },
    // 周界报警
    perimeterStatus(row, column) {
      if (row.alarmStatus.perimeterStatus == 0) {
        return '正常'
      } else {
        return '报警'
      }
    },
    // 存储设备
    storageDeviceStatus(row, column) {
      if (row.alarmStatus.storageDeviceStatus == 0) {
        return '正常'
      } else {
        return '报警'
      }
    },
    // 安防状态
    Securitystatus(row, column) {
      if (row.deploymentStatus == 1) {
        return '开启'
      } else {
        return '关闭'
      }
    },
    // 设备状态
    Securitystatusshebei(row, column) {
      if (row.deviceStatus == 0) {
        return '正常'
      } else {
        return '离线'
      }
    },
    // 获取安防报警数据
    getUserList() {
      this.loading = true
      this.$get(`/site/page`, {
        page: this.page,
        size: this.size
      }).then((res) => {
        if (res.code == 200) {
          if (res.data.list) {
            this.loading = false
            this.total = res.data.total
            this.tableData = res.data.list
          }
        }
      })
      // this.$get(`/user/page/${this.page}/${this.size}`).then(res => {
      //   this.tableData = res.data.list
      //   this.total = res.data.total
      //   this.loading = false
      // })
    },
    // 查询
    handleSearch() {
      this.$get('/site/page', {
        page: 1,
        size: 9999,
        siteName: this.value
      }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    // 重置
    handleReset() {
      this.value = ''
      this.getUserList()
    },
    // 分页切换
    handleCurrent(page) {
      this.page = page
      this.getUserList()
    },
    // 布防
    handleEdit(data, index) {
      console.log(data.deviceStatus)
      if (data.deviceStatus == 0) {
        if (this.currentid == this.spareId) {
          var status
          if (data.deploymentStatus == 1) {
            status = '关闭'
          } else if (data.deploymentStatus == 2) {
            status = '开启'
          }
          this.$refs.startcontrolClick.open(data, index, data.id, status)
        } else {
          this.$message({
            type: 'error',
            message: '暂无权限'
          })
        }
      } else {
        this.$message.error({message: "当前设备离线"})
      }

    },
    // 撤防
    handleDelete(data, index) {
      if (data.deviceStatus == 0) {
        if (this.currentid == this.spareId) {
          var status
          if (data.deploymentStatus == 1) {
            status = '关闭'
          } else if (data.deploymentStatus == 2) {
            status = '开启'
          }
          this.$refs.closecontrolClick.open(data, index, data.id, status)
        } else {
          this.$message({
            type: 'error',
            message: '暂无权限'
          })
        }
      } else {
        this.$message.error({message: "当前设备离线"})
      }

    },
    // 查看报警
    policeBtn(data) {
      this.$router.push({name: 'AlarmLog', params: {
          id: data.id,
          siteName:data.siteName
      }})  // 只能用 name
    }
  },
}
</script>
<style lang="scss" scoped>
.dialogAdd {
  color: #1d1d1d;
  font-weight: bold;
}

.contentAdd {
  .el-form {
    margin-top: calc(25 / 1080 * 100vh);

    .el-form-item {
      margin-bottom: calc(10 / 1080 * 100vh);
    }

    ::v-deep .el-form-item__label {
      color: #666;
      text-align: right;
      width: calc(100 / 1920 * 100vw);
      margin-right: calc(10 / 1920 * 100vw);
    }
  }

  .el-input {
    width: calc(250 / 1920 * 100vw);
  }

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .el-select {
    width: calc(250 / 1920 * 100vw);
  }
}

::v-deep .el-dialog {
  box-sizing: border-box;
  //background: #4073b6;
  border-radius: 16px;
  //width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

}

::v-deep .el-dialog__footer {
  padding-right: calc(35 / 1920 * 100vw);
}

::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}

::v-deep .menu_left {
  .el-input__inner {
    width: calc(200 / 1920 * 100vw);
  }
}

::v-deep .el-form-item__error {
  position: absolute;
  top: calc(48 / 1080 * 100vh);
  left: calc(125 / 1920 * 100vw) !important;
}

::v-deep .el-icon-circle-check:before {
  content: "" !important;
}
</style>
