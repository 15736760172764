<!-- 开启布控 -->
<template>
  <div>
    <el-dialog
      :close-on-click-modal="false" :visible.sync="dialogVisible" width="40%" top="0vh"
      :center="true" @close="cancel">
      <template slot="title">
        <div style=" font-size: 18px; font-weight: bold; color: #333333">变更布控状态</div>
      </template>
      <el-form label-position="top" label-width="100px" :rules="boxrules" :model="boxruleForm" ref="boxForm">
        <div class="Publishtask_center">
<!--          <div class="Publishtask_title">-->
<!--            <el-form-item label="操作闸门" prop="value">-->
<!--              <el-select style="width: 100%" v-model="boxruleForm.value" @change="cutGate">-->
<!--                <el-option v-for="item in gateOption" :key="item.id" :label="item.gateName" :value="item.id">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
          <div class="Publishtask_title">
            <el-form-item label="执行方式" class="item_form" prop="parentId">
              <el-radio-group @change="changeTheme" v-model="boxruleForm.parentId">
                <el-radio label="1">定时执行</el-radio>
                <el-radio label="2">立即执行</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="Publishtask_title" v-if="timedstart">
            <el-form-item label="执行时间设置" prop="dataTimer">
              <el-date-picker
                v-model="boxruleForm.dataTimer"
                type="datetime"
                placeholder="选择日期时间"
                clearable
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :default-time="['23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="Publishtask_button">
          <el-button type="primary" @click="boxSave">确认</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getGateMessage, getSitesIdList } from '../api/main'
import { gateAdd } from '../api'

export default {
  name: 'openwindowone',
  data () {
    return {
      dialogVisible: false,// 打开窗口
      boxrules: {
        dataTimer: [ // 定时执行时间
          {
            required: true,
            message: '请选择执行时间 ',
            trigger: 'blur'
          }
        ],
        parentId: [ // 定时执行 还是 审批之后执行
          {
            required: true,
            message: '请选择执行方式',
            trigger: 'change'
          }
        ],
      },
      boxruleForm: {
        parentId: '', // 定时执行 还是 审批之后执行
        dataTimer: '', // 定时执行时间
        type: 1, // 操作类型
        gateType: null,
        operationFront: null, // 操作前状态
        value: null,
      },
      gateOption: null, // 闸门列表
      timedstart: false, //
      sluicegate: null, // 闸门id
    }
  },
  methods: {
    // 打开弹窗
    open (data, index, siteId, status) {
      this.boxruleForm.gateType = index // 操作类型 4开启布控 5关闭布控
      this.boxruleForm.siteId = siteId  // 当前站点id
      this.boxruleForm.operationFront = status
      this.dialogVisible = true
      this.ValveList(siteId)
    },
    // 获取定时执行还是审批之后立即执行
    changeTheme (val) {
      if (val == 1) {
        this.timedstart = true
      } else {
        this.timedstart = false
      }
    },
    // 切换闸门
    cutGate (value) {
      // this.gateShow
      const id = value
      // 获取闸门 num
      for (const i of this.options) {
        if (i.id === id) {
          this.num = i.num
          i.num > 5 ? (this.gateShow = false) : (this.gateShow = true)
        }
      }
    },
    // 取消
    cancel () {
      this.$refs.boxForm.resetFields()
      this.boxruleForm = {}
      this.timedstart = false
      this.dialogVisible = false
    },
    // 确认布防
    boxSave () {
      this.$refs.boxForm.validate((val) => {
        if (val) {
          let params = {
            carryOutTime: this.boxruleForm.dataTimer, // 执行时间
            carryOutType: this.boxruleForm.parentId, // 执行类型
            gateType: this.boxruleForm.gateType, // 操作类型
            operands: '远程值守', // 操作对象
            operationFront: this.boxruleForm.operationFront, // 操作前的状态
            siteId: this.boxruleForm.siteId,// 当前站点id
            type: 2, // 变更布控状态
            // gateId: this.boxruleForm.value, // 闸门id
          }
          this.$axios.post(`/applyRecord/insertApplyRecord`, params).then((res) => {
            if (res.code == 200) {
              this.$message.success({ message: '执行成功，等待设备响应' })
              this.cancel()
              this.dialogVisible = false
            }
          })
        }
      })

    },
    // 获取阀门列表
    ValveList (val) {
      getSitesIdList({ id: val }).then((res) => {
        this.gateOption = res.data
      })
    },
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-date-editor .el-input__inner {
  border: 1px solid #DCDFE6 !important;
  width: 110% !important;
}

/deep/ .el-date-editor .el-input__icon {
  right: -20px !important;
  bottom: -3px;
}

/deep/ .el-date-editor .el-input__suffix {
  display: none !important;
}

/deep/ .el-dialog .el-dialog__body .el-form-item__error {
  left: 0px !important;
}

.Publishtask_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .Publishtask_title {
    padding-top: 10px;
    width: 300px;

    /deep/ .el-form-item {
      width: 100% !important;
      margin: 10px !important;

      .el-form-item__content {
        .el-cascader {
          width: 100%;
        }

        /deep/ .el-form-item__error {
          left: 0 !important;
        }
      }
    }
  }

  .Publishtask_textarea {
    padding-top: 10px;
    width: 100%;
  }
}

.Publishtask_button {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}


</style>
